<template>
  <v-app-bar app flat height="100">
    <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="toggleDrawer" />
    <v-container >
      <v-row align="center">
        <v-img :src="require('@/assets/logo.png')" class="ml-5" contain height="55" width="55" max-width="55"
          @click.stop="$vuetify.goTo(0)" />

        <v-btn class="hidden-sm-and-down" text href="/" @click.stop="">
          الرئيسية
        </v-btn>

        <v-btn class="hidden-sm-and-down" text href="/videos" @click.stop="">
          الفديوهات
        </v-btn>

        <!-- <v-btn class="hidden-sm-and-down" text href="/articles" @click.stop="">
          المقالات
        </v-btn> -->

        <v-btn class="hidden-sm-and-down" text href="/posts" @click.stop="">
          المنشورات
        </v-btn>
        <v-btn class="hidden-sm-and-down" text href="/#about" @click.stop="">
          من نحن
        </v-btn>

        <v-btn class="hidden-sm-and-down" text href="/#contact" @click.stop="">
          تواصل معنا
        </v-btn>

        <v-menu transition="slide-x-transition" bottom right max-height="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="hidden-sm-and-down" v-bind="attrs" v-on="on">
              الوضع المضلم
            </v-btn>
          </template>

          <v-list >

            <v-list-item >

              <v-radio-group v-model="radioGroup">
                <v-radio label="تشغيل" :value="1" @change="switchTheme(true)"></v-radio>
                <v-radio label="ايقاف" :value="2" @change="switchTheme(false)"></v-radio>
              </v-radio-group>
            </v-list-item>

          </v-list>
        </v-menu>



        <v-spacer />
        <v-btn  v-for="(link, i) in links" :key="i" :href="link.href" class="hidden-sm-and-down ml-0 mr-3" target="_blank" icon>
          <v-icon :color="link.color" v-text="link.icon" />
        </v-btn>
        <v-btn href="https://www.tiktok.com/@center.qamar" class="hidden-sm-and-down ml-0 mr-3" target="_blank" icon><v-img max-height="26" max-width="26"
              :src="require('@/assets/tiktok.png')"></v-img></v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";

export default {
  name: "CoreAppBar",
  data: () => ({
    links: [
      {
        href: 'https://www.facebook.com/alqamar.cen',
        icon: 'fa-facebook-square',
        color: '#3b5998 '
      },
      {
        href: 'https://www.instagram.com/alqamar_cen',
        icon: 'fa-instagram',
        color: '#fb3958'
      },
      {
        href: 'https://t.me/alqamar_cen',
        icon: 'fa-telegram',
        color: '#2AABEE'
      },
      {
        href: 'https://www.youtube.com/channel/UCpN36-3mIoIGzJjxBZJ7HKw',
        icon: 'fa-youtube',
        color: '#FF0000'
      },
    ],

  }),
  created() {
    //this.getTagsByType();
  },
  computed: {

    radioGroup() {
      if (this.$vuetify.theme.dark)
        return 1;
      else
        return 2;
    }

  },
  watch: {
    // search(val){
    //   if(val=='')
    //   this.setSearch('')
    // }

  },


  methods: {
    ...mapMutations(["toggleDrawer"]),
    onClick(e, item) {
      e.stopPropagation();

      if (item.to || !item.href) return;

      this.$vuetify.goTo(item.href.endsWith("!") ? 0 : item.href);
    },
    switchTheme(idDark) {
      this.$vuetify.theme.dark = idDark;
      localStorage.setItem('theme', idDark ? 'dark' : 'light');
    }
  },
};
</script>
